<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title :text="'Generate ' + data.title + ' Sheet'" />

	<app-panel-content>

		<app-input-text label="Pages" :stacked="true" v-model="model.range" :validation="$v.model.range" placeholder="Enter range (optional)" />
		
	</app-panel-content>

	<app-panel-text :danger="is.error" v-if="is.error" text="The page range you entered is outside the total number of pages." />

	<app-panel-content>

		<app-button text="Generate" :theme="(is.error) ? 'red' : false" :loading="is.loading" v-on:click="onGenerateClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	props: ['data'],

	data: function() {

		return {
			is: {
				loading: false,
				error: false
			},
			model: {
				range: ''
			}
		}

	},

	validations: {
		model: {
			range: {
				required
			}
		}
	},

	methods: {

		onGenerateClick: function() {

			this.is.loading = true
			this.is.error = false

			this.$api.post(this.data.endpoint + '/sheet', {
				index: this.data.index,
				pages: this.model.range,
				perpage: this.data.perpage
			}).then(function(json) {

				this.$present(json.pdf)

				this.is.loading = false
				this.$emit('close')

			}.bind(this), function() {

				this.is.loading = false
				this.is.error = true

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
